import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { get, ref } from 'firebase/database';
import { db } from './firebase';
import './ProductsPage.css';

function ProductsPage() {
    const { niche, priceRange } = useParams();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    const nicheMapping = {
        'laptop': 'Laptops',
        'washing-machine': 'Washing Machines',
        'microwave': 'Microwaves',
        'tv': 'TVs',
        'ac': 'ACs',
        'refrigerator': 'Refrigerators',
    };

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                const productsRef = ref(db, `products/${niche}/${priceRange}`);
                const snapshot = await get(productsRef);

                if (snapshot.exists()) {
                    setProducts(Object.values(snapshot.val()));
                } else {
                    console.log("No products found!");
                }
            } catch (error) {
                console.error("Error fetching products:", error);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, [niche, priceRange]);

    const handleImageLoad = (e) => {
        e.target.parentNode.classList.remove('loading');
    };

    const handleAffiliateLinkClick = (productName) => {
        // Send a custom event to Facebook Pixel
        window.fbq('track', 'AffiliateLinkClick', {
            product_name: productName
        });
    };
    

    return (
        <div className='products'>
            {loading ? (
                <div className="product-loader"></div>
            ) : (
                <div className="products-page">
                    <h2 className="text-center products-header-line">Top {products.length} {nicheMapping[niche]} under {priceRange}</h2>
                    <div className="intro-section">
    <p>We have researched and analyzed online reviews to bring you the best {nicheMapping[niche]} under {priceRange}. Our comprehensive list takes into account various factors like performance, durability, and value for money to ensure you make the best purchase decision.</p>
</div>

                    {products.map((product, index) => (
                        <section key={index} className="productCard">
                            <div className="imageSection">
                            <div className="imageWrapper1 loading">
                                <img className="productImage" src={product.imageUrl} alt={product.name} onLoad={handleImageLoad} />
                            </div>
                            </div>
                            <div className="detailsSection">
                                <h3><a href={product.affiliateLink} className='product-name' title={product.name} onClick={() => handleAffiliateLinkClick(product.name)}>{product.name}</a></h3>
                                <div className="specifications">
                                    <p className="heading">Key Specification &amp; Features</p>
                                    <ul>
                                        {product.specifications && Object.entries(product.specifications).map(([key, value]) => (
                                            <li key={key}>{value}</li>
                                        ))}
                                    </ul>
                                </div>
                                {/* {product.whoShouldBuy && (
                                    <div className="whoShouldBuy">
                                        <strong>Who should buy:</strong> {product.whoShouldBuy}
                                    </div>
                                )} */}
                                {product.CTF && (
                                    <div className="Analysis">
                                        <strong>Customer Trust Factor:</strong> {product.CTF}%
                                    </div>
                                )}
                                {product.Analysis && (
                                    <div className="Analysis">
                                        <strong>Online Review Analysis</strong> {product.Analysis}
                                    </div>
                                )}
                                <div className="priceSection">
                                    <a href={product.affiliateLink} className="btn-buy" onClick={() => handleAffiliateLinkClick(product.name)}>Checkout for latest price</a>
                                </div>
                            </div>
                        </section>
                    ))}
                </div>
            )}
        </div>
    );
}

export default ProductsPage;
