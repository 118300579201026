import React from 'react';
import Slider from 'react-slick';
import './Carousel.css';
import { Link } from 'react-router-dom';

function Carousel({ data }) {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    const nicheMapping = {
        'laptop': 'Laptops',
        'washing-machine': 'Washing Machines',
        'microwave': 'Microwaves',
        'tv': 'TVs',
        'ac': 'ACs',
        'refrigerator': 'Refrigerators',
    };

    const carouselItems = Object.keys(data)
    .map(section => {
        const sectiongroup = data[section];
        const sectiongroupFirst = Object.keys(sectiongroup)[0];
        const firstProduct = sectiongroup[sectiongroupFirst];
        if (Object.keys(firstProduct).length > 2) {
            return {
                src: firstProduct.imageUrl,
                caption: `Discover the best ${nicheMapping[firstProduct.niche]} under ${section}`,
                section: section,
                niche: firstProduct.niche
            };
        }
        return null;
    })
    .filter(item => item !== null); // Remove null items from the array
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: 'block', background: 'black' }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: 'block', background: 'black' }}
                onClick={onClick}
            />
        );
    }

    return (
        <div className="carousel">
            <Slider {...settings}>
                {carouselItems.map((item, index) => (
                    <Link to={`/${item.niche}/${item.section}`} key={index} className="carousel-link">
                    <div key={index} className="carousel-item">
                        <img src={item.src} alt={item.caption} className="carousel-image" />
                        <div className="carousel-caption">{item.caption}</div>
                    </div>
                    </Link>
                ))}
            </Slider>
        </div>
    );
}

export default Carousel;
