import React from 'react';
import Header from './Header';
import './ContactUs.css';
import { Link } from 'react-router-dom';

function ContactUs() {
  return (
    <div className="contact-page">
      <div className="container">
        <section className="contact-section">
          <div className="contact-header-text">
            <h2>Connect With Us</h2>
          </div>

          <div className="contact-form-container">
            <div className="contact-form-column">
              <form className="contact-form" action="https://formspree.io/f/mrgwpoeq" method="POST">
                <div className="contact-input-group">
                    <label className="contact-form-label" htmlFor="contactName">Name</label>
                    <input type="text" className="contact-input-box form-control" id="contactName" name="userName" required />
                </div>

                <div className="contact-input-group">
                    <label className="contact-form-label" htmlFor="contactEmail">Email</label>
                    <input type="email" className="contact-input-box form-control" id="contactEmail" name="userEmail" required />
                </div>

                <div className="contact-input-group">
                    <label className="contact-form-label" htmlFor="contactMessage">Message</label>
                    <textarea className="contact-input-box form-control" id="contactMessage" name="userMessage" rows="5" required></textarea>
                </div>

                <div className="contact-button-group">
                    <button type="submit" className="contact-btn-send">Send</button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ContactUs;
