import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';


const firebaseConfig = {
    apiKey: "AIzaSyBAy7GlHaZuFpY_fNduLQHi2lWAxURugcY",
    authDomain: "review-test-8dc49.firebaseapp.com",
    databaseURL: "https://review-test-8dc49-default-rtdb.firebaseio.com",
    projectId: "review-test-8dc49",
    storageBucket: "review-test-8dc49.appspot.com",
    messagingSenderId: "773627102626",
    appId: "1:773627102626:web:13acfeb958ab29d608defb",
    measurementId: "G-NLMG3SXH8T"
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const storage = getStorage(app);
const auth = getAuth(app);


export { db, storage, auth };
export default app;
