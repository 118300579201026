import React from 'react';
import './HomePage.css';
import { Link } from 'react-router-dom';

function HomePage() {
    return (
        <div className="homepage">
            <div className="hero-section">
                <h2>Online reviews are analyzed to determine the Customer Trust Factor, ensuring you find the best products</h2>
                <p>Planning to buy best products in the future, stay updated with us:</p>
                <a href="https://t.me/whatzLatest" className="telegram-cta" target="_blank" rel="noopener noreferrer">Join our Telegram Channel</a>
            </div>


            <div className="info-section">
                <Link 
                    to="/laptop" 
                    className="info-item"
                    onClick={() => window.fbq('trackCustom', 'NicheClick', {niche: 'Laptop'})}
                >
                    <div className="imageWrapper">
                        <img src="/homepagepics/laptop.jpg" alt="Laptop" />
                    </div>
                    <h3 className="nicheText">How to choose a Laptop</h3>
                </Link>
                <Link 
                    to="/ac" 
                    className="info-item"
                    onClick={() => window.fbq('trackCustom', 'NicheClick', {niche: 'Air Conditioner'})}
                >
                    <div className="imageWrapper">
                        <img src="/homepagepics/ac.jpg" alt="Air Conditioner" />
                    </div>
                    <h3 className="nicheText">How to choose an Air Conditioner</h3>
                </Link>
                <Link 
                    to="/washing-machine" 
                    className="info-item"
                    onClick={() => window.fbq('trackCustom', 'NicheClick', {niche: 'Washing Machine'})}
                >
                    <div className="imageWrapper">
                        <img src="/homepagepics/washingmachine.jpg" alt="Washing Machine" />
                    </div>
                    <h3 className="nicheText">How to choose a Washing Machine</h3>
                </Link>
                <Link 
                    to="/tv" 
                    className="info-item"
                    onClick={() => window.fbq('trackCustom', 'NicheClick', {niche: 'TV'})}
                >
                    <div className="imageWrapper">
                        <img src="/homepagepics/tv.jpg" alt="TV" />
                    </div>
                    <h3 className="nicheText">How to choose a TV</h3>
                </Link>
                <Link 
                    to="/refrigerator" 
                    className="info-item"
                    onClick={() => window.fbq('trackCustom', 'NicheClick', {niche: 'Refrigerator'})}
                >
                    <div className="imageWrapper">
                        <img src="/homepagepics/refrigerator.jpg" alt="Refrigerator" />
                    </div>
                    <h3 className="nicheText">How to choose a Refrigerator</h3>
                </Link>
                <Link 
                    to="/microwave" 
                    className="info-item"
                    onClick={() => window.fbq('trackCustom', 'NicheClick', {niche: 'Microwave'})}
                >
                    <div className="imageWrapper">
                        <img src="/homepagepics/microwave.jpg" alt="Microwave" />
                    </div>
                    <h3 className="nicheText">How to choose a Microwave</h3>
                </Link>
            </div>



            {/* Other sections of the homepage can be added here */}
        </div>
    );
}

export default HomePage;
