import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './HomePage';
import NichePage from './NichePage';
import Header from './Header';
import Footer from './Footer';
import './App.css';
import ProductsPage from './ProductsPage';
import { db } from './firebase.js';
import { get, ref } from 'firebase/database';
import React, { useEffect, useState } from 'react';
import PrivacyPolicy from './PrivacyPolicy';
import ContactUs from './ContactUs';

function App() {
    const [allData, setAllData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await get(ref(db, `/products`));
                setAllData(response.val());
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    return (
        <Router>
            <div className="App">
                <Header />
                <main>
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/home" element={<HomePage />} />
                        <Route path="/:niche" element={<NichePage data={allData} />} />
                        <Route path="/:niche/:priceRange" element={<ProductsPage />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="/contact-us" element={<ContactUs />} />
                    </Routes>
                </main>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
