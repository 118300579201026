import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
    const [menuOpen, setMenuOpen] = useState(false);
    const menuOpenRef = useRef(menuOpen);  // <-- Create a ref to store the current value of menuOpen
    const menuRef = useRef(null); // <-- This ref should be attached to the .header-menu div

    useEffect(() => {
        menuOpenRef.current = menuOpen; // <-- Update the ref every time menuOpen changes
    }, [menuOpen]);

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target) && menuOpenRef.current) {
            setMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const niches = [
        { name: 'Laptop', path: 'laptop' },
        { name: 'Washing Machine', path: 'washing-machine' },
        { name: 'AC', path: 'ac' },
        { name: 'TV', path: 'tv' },
        { name: 'Refrigerator', path: 'refrigerator' },
        { name: 'Microwave', path: 'microwave' },
    ];

    return (
        <header className="header">
            <nav className="header-nav">
                <a href="/" className="header-logo">
                    <img src="/logohai2.png" alt="Logo" />
                </a>
                <button className="header-nav-toggle" onClick={() => setMenuOpen(!menuOpen)}>
                    <svg viewBox="0 0 24 24">
                        <g transform="translate(2, 5)">
                            <rect x="0" y="12" width="20" height="2"></rect>
                            <rect x="0" y="6" width="20" height="2"></rect>
                            <rect x="0" y="0" width="20" height="2"></rect>
                        </g>
                    </svg>
                </button>
                {menuOpen && (
                    <div className="header-menu" ref={menuRef}>
                        <Link to="/" onClick={() => setMenuOpen(false)}>Home</Link>
                        {niches.map(niche => (
                            <Link key={niche.path} to={`/${niche.path}`} onClick={() => setMenuOpen(false)}>{niche.name}</Link>
                        ))}
                        <Link to="/contact-us" onClick={() => setMenuOpen(false)}>Contact us</Link>
                    </div>
                
                )}
                <div className="header-action-bar">
                    <Link to="/">Home</Link>
                    <Link to="/laptop">Laptop</Link>
                    <Link to="/washing-machine">Washing Machine</Link>
                    <Link to="/ac">AC</Link>
                    <Link to="/tv">TV</Link>
                    <Link to="/refrigerator">Refrigerator</Link>
                    <Link to="/microwave">Microwave</Link>
                    <Link to="/contact-us">Contact us</Link>
                </div>
            </nav>
        </header>
    );
}

export default Header;
