import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer className="footer mt-auto py-3 bg-dark text-white">
                <div className="text-center">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                </div>
                <div className="text-center">
                    <span>&copy; 2023 whatzlatest</span>
                </div>
            </footer>
    );
}

export default Footer;
