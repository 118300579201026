import React from 'react';
import Header from './Header';
import { Link } from 'react-router-dom';

function PrivacyPolicy() {
    return (
            <div className="container" style={{ maxWidth: '800px', margin: '20px auto', padding: '20px', backgroundColor: '#FFFFFF', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                <h2>Privacy Policy</h2>
                <hr />

                <h3>1. Introduction</h3>
                <p>Welcome to WhatzLatest. We respect the privacy of our users and are committed to protecting it through our compliance with this policy.</p>

                <h3>2. Information We Collect</h3>
                <p>We may collect and process the following data about you:</p>
                <ul>
                    <li><strong>Analytical Data</strong>: We may collect data about your behavior on our website. This allows us to improve our services and provide better content.</li>
                </ul>

                <h3>3. How We Use Your Information</h3>
                <p>The information we collect is used for various purposes:</p>
                <ul>
                    <li>To improve our website and provide a better user experience.</li>
                    <li>To understand how our users interact with our website.</li>
                </ul>

                <h3>5. Third-Party Links</h3>
                <p>Our website may contain links to third-party websites that are not operated by us. Clicking on those links may allow third parties to collect or share data about you. We are not responsible for their privacy statements.</p>

                <h3>6. Your Rights</h3>
                <p>Depending on where you are, you may have certain rights concerning your personal data:</p>
                <ul>
                    <li><strong>Access</strong>: You may have the right to access and receive a copy of the personal information we hold about you.</li>
                    <li><strong>Correction</strong>: You may have the right to correct any personal information held about you that is inaccurate.</li>
                    <li><strong>Deletion</strong>: You may have the right to request deletion of your personal information.</li>
                </ul>

                <h3>7. Changes to the Privacy Policy</h3>
                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

                <h3>8. Contact Us</h3>
                <p>If you have any questions about this Privacy Policy, please contact us.</p>
            </div>
    );
}

export default PrivacyPolicy;
