import { useParams, Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Carousel from './Carousel';
import './NichePage.css';
import firebase from './firebase.js';
import { db } from './firebase.js';
import { get, ref } from 'firebase/database';

function NichePage(props) {
    const { niche } = useParams();
    const [localData, setLocalData] = useState(null);
    const nicheMapping = {
        'laptop': 'Laptops',
        'washing-machine': 'Washing Machines',
        'microwave': 'Microwaves',
        'tv': 'TVs',
        'ac': 'ACs',
        'refrigerator': 'Refrigerators',
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await get(ref(db, `/products/${niche}`));
                setLocalData(response.val());
            } catch (error) {
                console.error(error);
            }
        };

        if (!props.data) {
            fetchData();
        }
    }, [niche, props.data]);

    const data = props.data ? props.data[niche] : localData;

    if (!data) {
        return null; // render nothing until data is fetched or passed as props
    }

    return (
        <div className="niche-page">
            <Carousel data={data} />
            <h1>{data.header}</h1>
            <span>{data.publishedDate}</span>
            <div dangerouslySetInnerHTML={{ __html: data.description }} />
            <p>We have analyzed the best products according to each section:</p>
            <ul>
                {Object.keys(data).map((section, index) => {
                    if(section !== 'header' && section !== 'description' && section !== 'publishedDate') {
                        return (
                            <li key={index}>
                                <Link to={`/${niche}/${section}`}>{nicheMapping[niche]} under {section}</Link>
                            </li>
                        );
                    } else {
                        return null; // Do not render the link for non-price sections
                    }
                })}
            </ul>
        </div>
    );
}

export default NichePage;
